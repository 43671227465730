import "./output.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { motion } from "framer-motion";
import {
  Autoplay,
  Navigation,
  Pagination,
  EffectCoverflow,
} from "swiper/modules";
import { auth, db } from "../config/firebaseConfig";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Countdown from "react-countdown";
import Footer from "./footer";
import FancyText from "@carefully-coded/react-text-gradient";
import { Link, Navigate } from "react-router-dom";
import WavyText from "./WeavyText";
import { Image } from "react-bootstrap";
import { signOut } from "firebase/auth";
import { Backdrop, CircularProgress } from "@mui/material";
import AlertDialogSlide from "./singOut";

function Home() {
  const [publicidad, setPublicidad] = useState([]);

  const [user, setUser] = useState([
    {
      nombre: "",
      email: "",
      image: "",
      portada: "",
      f1: [],
      imgFondo: "",
      imgIcon: "",
      premium: "",
    },
  ]);

  const [favoritos, setFavoritos] = useState([]);

  useEffect(() => {
    const collectionRefUser = collection(db, "Usuarios");
    const qUser = query(
      collectionRefUser,
      where("correo", "==", auth.currentUser.email)
    );
    const unsubscribeUser = onSnapshot(qUser, (querySnapshot) => {
      setUser(
        querySnapshot.docs.map((doc) => ({
          nombre: doc.data().nombre,
          email: doc.data().correo,
          image: doc.data().image,
          portada: doc.data().portada,
          f1: doc.data().f1,
          imgFondo: doc.data().imgFondo,
          imgIcon: doc.data().imgIcon,
          premium: doc.data().premium,
        }))
      );
      setFavoritos(user[0].f1);
      console.log(user[0].nombre + " " + user[0].f1);
    });
    return unsubscribeUser;
  }, []);

  useEffect(() => {
    const collectionRef = collection(db, "Publicidad");
    const q = query(collectionRef, where("prioridad", "==", 0));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setPublicidad(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          cliente: doc.data().cliente,
          resumen: doc.data().resumen,
          detalles: doc.data().detalles,
          imagen: doc.data().imagen,
          estrellas: doc.data().estrellas,
          prioridad: doc.data().prioridad,
          abrido: doc.data().abrido,
          horario: doc.data().horario,
          direccion: doc.data().direccion,
          telefono: doc.data().telefono,
          galeria: doc.data().galeria,
        }))
      );
    });
    return unsubscribe;
  }, []);

  const [index, setIndex] = useState([
    {
      id: 1,
      estado: "Puebla",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FPuebla%2FPuebla.png?alt=media&token=e91d6a44-24a1-461a-8ddd-ee028a998b70",
      imgFondo:
        "https://turixcam-images.b-cdn.net/puebla/Zacatlan/Recursos/Puebla.jpg",
    },
    {
      id: 2,
      estado: "Hidalgo",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FHidalgo%2FHidalgo.png?alt=media&token=33721372-ed9e-4095-bb0f-db7ae3964116",
      imgFondo:
        "https://turixcam-images.b-cdn.net/hidalgo/Mineral%20del%20Chico/ChicoCentroFotoOnce.jpg",
    },
    {
      id: 3,
      estado: "Veracruz",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FVeracruz%2FVeracruz.png?alt=media&token=77480979-4b96-4db9-b13c-7156a029fd65",
      imgFondo:
        "https://turixcam-images.b-cdn.net/Recursos%20WEB/Portadas/V2/Por6c.png",
    },
    {
      id: 4,
      estado: "Tlaxcala",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FTlaxcala_WIP%2FTlaxcala.png?alt=media&token=d409e779-8ae9-4054-9a4f-64a6d88b7a29",
      imgFondo:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FFondos%2FTlaxcala%2FF1.jpg?alt=media&token=aca050b1-d031-4b25-a36f-6aa7895fb056",
    },
  ]);

  const [replay, setReplay] = useState(true);
  const [replay1, setReplay1] = useState(true);

  const [camaras, setCamaras] = useState([]);

  useEffect(() => {
    const collectionRef1 = collection(db, "productscams");
    const q2 = query(collectionRef1, orderBy("createdAt", "asc"));
    const unsubscribe1 = onSnapshot(q2, (querySnapshot1) => {
      console.log("Datos obtenidos");
      setCamaras(
        querySnapshot1.docs.map((doc) => ({
          id: doc.id,
          createdAt: doc.data().createdAt,
          title: doc.data().title,
          background: doc.data().background,
        }))
      );
    });

    return unsubscribe1;
  }, []);

  useEffect(() => {
    setFavoritos(user[0].f1);
  });

  const FavCams = camaras.filter((object, indice) => {
    return favoritos.includes(indice);
  });

  const [load, setLoad] = useState(false);

  const [cierre, setCierre] = useState(false);

  const signOutFunction = async () => {
    try {
      await signOut(auth).then(
        console.log("User signed out"),
        setCierre(true),
        setLoad(false),
        console.log("Varuable cierre: " + cierre)
      );
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <div className="img"></div>

      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          style={{ padding: 10, backgroundColor: "rgba(0,0,0,0.010)" }}
        >
          <Container fluid>
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              style={{ borderWidth: 0, width: 50, height: 50 }}
              children={
                <div
                  style={{
                    height: 50,
                    width: 50,
                    alignSelf: "flex-start",
                    marginLeft: -12,
                    marginTop: -4,
                  }}
                >
                  <img
                    style={{ height: 50, width: 50, borderRadius: 5 }}
                    src={user[0].image}
                  />
                </div>
              }
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
            >
              <Offcanvas.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "rgba(250,250,250,0.6)",

                    margin: -10,
                    borderRadius: 15,
                    borderColor: "grey",
                    borderWidth: 2,
                  }}
                >
                  <Image
                    src={user[0].portada}
                    style={{
                      alignSelf: "center",
                      width: "300px",
                      height: "150px",
                      borderTopLeftRadius: 12,
                      borderTopRightRadius: 12,
                    }}
                  />
                  <Image
                    src={user[0].image}
                    style={{
                      alignSelf: "center",
                      width: "100px",
                      height: "100px",
                      marginTop: -50,
                      borderColor: "gold",
                      borderWidth: 1,
                    }}
                    roundedCircle
                  />
                  <h2
                    style={{ textAlign: "center", fontSize: 24, marginTop: 20 }}
                  >
                    {user[0].nombre}
                  </h2>
                  <a
                    style={{
                      textAlign: "center",
                      fontSize: 16,
                      color: "gray",
                      marginTop: -10,
                      marginBottom: 20,
                    }}
                  >
                    {user[0].email}
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 10,
                    margin: 5,
                    borderRadius: 15,
                    justifyContent: "space-between",
                    alignContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(250,250,250,0.6)",
                      borderRadius: 15,
                      borderColor: "grey",
                      borderWidth: 2,
                      padding: 10,
                      margin: 10,
                    }}
                  >
                    <a
                      style={{
                        fontSize: 16,
                        fontWeight: "bolder",
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Modificar usuario
                    </a>
                  </div>
                 <AlertDialogSlide />
                </div>
                {cierre === true && <Navigate to={"/"} />}
                <div style={{ margin: 20, alignSelf: "flex-start" }}>
                  <h1
                    style={{
                      fontSize: 30,
                      color: "rgba(0,0,0,0.6)",
                      fontWeight: "bolder",
                      textAlign: "left",
                    }}
                  >
                    Favoritos
                  </h1>
                  <Swiper
                    // install Swiper modules
                    effect={"coverflow"}
                    modules={[
                      Autoplay,
                      EffectCoverflow,
                      Navigation,
                      Pagination,
                    ]}
                    spaceBetween={20}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 0,
                      depth: 100,
                      modifier: 2.5,
                    }}
                    autoplay={{
                      delay: 2400,
                      disableOnInteraction: false,
                    }}
                    style={{ width: 440, left: -50, marginTop: 20 }}
                    pagination={{ clickable: true }}
                  >
                    {FavCams.map((i) => (
                      <SwiperSlide
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: 200,
                          height: 300,
                          borderRadius: 15,
                          backgroundColor: "rgba(0,0,0,0.8)",
                        }}
                        key={i.id}
                        {...i}
                      >
                        <Link
                          style={{ marginTop: 0, height: 500 }}
                          to={"buscador"}
                        >
                          <img
                            style={{
                              width: 200,
                              height: 250,
                              marginBottom: 10,
                              borderTopLeftRadius: 15,
                              borderTopRightRadius: 15,
                              marginBottom: 0,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                            src={i.background}
                          />
                        </Link>
                        <a
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bolder",
                            color: "white",
                            marginBottom: "25px",
                          }}
                        >
                          {i.title}
                        </a>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <header className="App-header">
        <img
          style={{
            height: 200,
            width: 400,
            marginTop: 50,
            position: "relative",
          }}
          src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLogos%2FLogo_transparente.png?alt=media&token=3165b93b-50ca-4b53-8a28-c61cdb1d679f"
        />
        <div className="example-container div">
          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} />
        </div>
      </header>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          width: 1300,
          justifyContent: "center",
          margin: 10,
          paddingTop: 15,
          borderRadius: 15,
          marginBottom: 100,
          height: 750,
          backgroundColor: "rgba(240,240,240,0.4)",
        }}
      >
        <WavyText text="EXPLORA LUGARES" replay={replay} />
        <div
          style={{
            width: 1300,
            alignSelf: "center",
            justifyContent: "center",
            marginTop: 50,
            padding: 0,
            marginBottom: 10,
            height: 650,
          }}
        >
          <Swiper
            // install Swiper modules
            effect={"coverflow"}
            modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
            spaceBetween={20}
            slidesPerView={"auto"}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            autoplay={{
              delay: 2900,
              disableOnInteraction: true,
            }}
            onSlidePrevTransitionEnd={() => {
              setReplay(!replay);
              setTimeout(() => setReplay(true), 600);
            }}
            pagination={{ clickable: true }}
          >
            {index.map((item) => (
              <SwiperSlide
                style={{
                  display: "flex",
                  width: 350,
                  height: 500,
                  borderRadius: 15,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  backgroundImage: `url(${item.imgFondo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                key={item.id}
                {...item}
              >
                <Link to={"camaras"} state={{ estado: item.estado }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: 130,
                      width: 350,
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      borderRadius: 0,
                      background: "rgba(0,0,0,0.4)",
                      borderWidth: 0,
                      borderColor: "rgba(255,255,255,0.2)",
                    }}
                  >
                    <img
                      src={item.portada}
                      style={{ height: 150, width: 300, alignSelf: "center" }}
                    />
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div
        style={{
          width: 1300,
          alignSelf: "center",
          marginTop: 100,
          padding: 10,
          borderRadius: 15,
          marginBottom: 200,
          height: 750,
          backgroundColor: "rgba(240,240,240,0.4)",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 30,
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <WavyText text="DESCUBRE MAS" replay={replay1} />
        </div>

        <Swiper
          // install Swiper modules
          effect={"coverflow"}
          modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={"auto"}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          autoplay={{
            delay: 2400,
            disableOnInteraction: false,
          }}
          onSlidePrevTransitionEnd={() => {
            setReplay1(!replay1);
            setTimeout(() => setReplay1(true), 600);
          }}
          pagination={{ clickable: true }}
        >
          {publicidad.map((item) => (
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
                width: 500,
                height: 500,
                borderRadius: 15,
                backgroundColor: "rgba(0,0,0,0.8)",
              }}
              key={item.id}
              {...item}
            >
              <Link style={{ marginTop: 0, height: 500 }} to={"buscador"}>
                <img
                  style={{
                    width: 500,
                    height: 400,
                    marginBottom: 10,
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    marginBottom: 0,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  src={item.imagen}
                />
              </Link>
              <a
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  fontWeight: "bolder",
                  color: "white",
                  marginBottom: "25px",
                }}
              >
                {item.cliente}
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
