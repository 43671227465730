import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Buscador from "./screens/buscador";
import Login from "./components/login";
import Camaras from "./components/camaras";
import Live from "./components/live";
import Registro from "./components/Registro";
import ForgetPassword from "./components/ForgetPassword";
import ListaC from "./components/listClientes";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path ="/registro" element={<Registro/>} />
      <Route path ="/olvide-contraseña" element={<ForgetPassword/>} />
      <Route path="home" element={<Home />} />
      <Route path="home/camaras" element={<Camaras />} />
      <Route path="home/camaras/live" element={<Live />} />
      <Route path="home/camaras/live/lista" element={<ListaC />} />
      <Route path="home/buscador" element={<Buscador />} />
    </Routes>
  );
}

export default App;
