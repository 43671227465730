import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { auth } from "../config/firebaseConfig";
import { signOut } from "firebase/auth";
import { CircularProgress } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [nav, setNav] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await signOut(auth).then(console.log("Cierre de sesion exitoso."));
  };

  const navigate = useNavigate()

  const progress = () => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
      
      navigate('/')
      window.location.reload();
    }, 2000);
  };

  return (
    <React.Fragment>
      <div
        onClick={() => handleClickOpen()}
        style={{
          backgroundColor: "red",
          borderRadius: 15,
          borderColor: "grey",
          borderWidth: 2,
          padding: 10,
          margin: 10,
        }}
      >
        <a
          style={{
            fontSize: 16,
            fontWeight: "bolder",
            color: "rgba(250,250,250,0.9)",
          }}
        >
          Cerrar sesion
        </a>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ borderRadius: 15 }}
      >
        <DialogTitle>{"¿Esta seguro que desea cerrar sesion?"}</DialogTitle>
        <DialogContent>
          {load == false && (
            <DialogContentText id="alert-dialog-slide-description">
              Si lo hace, tendra que volver a inicar sesion para acceder a
              Turixcam
            </DialogContentText>
          )}
          {load == true && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <svg width={0} height={0}>
                <defs>
                  <linearGradient
                    id="my_gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress
                sx={{ "svg circle": { stroke: "url(#my_gradient)" }, alignSelf: "center", margin: 10 }}
              />
              <DialogContentText
                sx={{ textAlign: "center" }}
                id="alert-dialog-slide-description"
              >
                Cerrando sesion
              </DialogContentText>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No, quedarme aqui.</Button>
          <Button onClick={() => (progress(), handleLogout())}>
            Si, cerrar sesion
          </Button>
        </DialogActions>
      </Dialog>
      {nav === true && <Navigate to={"/"} />}
    </React.Fragment>
  );
}
