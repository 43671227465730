// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJEzvoQsoD6nuakhRYHp7jwGavXRGpeo0",
  authDomain: "turixcamapp.firebaseapp.com",
  databaseURL: "https://turixcamapp-default-rtdb.firebaseio.com",
  projectId: "turixcamapp",
  storageBucket: "turixcamapp.appspot.com",
  messagingSenderId: "1053001979109",
  appId: "1:1053001979109:web:7d08b7d5fcacd9b4e7f00c",
  measurementId: "G-4QPMDKLCCJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app)

export default app;