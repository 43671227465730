import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Autoplay,
  Navigation,
  Pagination,
  EffectCoverflow,
} from "swiper/modules";
import { db } from "../config/firebaseConfig";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Footer from "./footer";

export default function Camaras() {
  const location = useLocation();

  const item = location.state.estado;

  const [camaras, setCamaras] = React.useState([
    {
      title: "S",
    },
  ]);

  React.useEffect(() => {
    const collectionRef1 = collection(db, "productscams");
    const q2 = query(collectionRef1, where("estado", "==", item));
    const unsubscribe1 = onSnapshot(q2, (querySnapshot1) => {
      console.log("Dartos obtenidos");
      setCamaras(
        querySnapshot1.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          portada: doc.data().portada,
          background: doc.data().background,
        }))
      );
    });
    return unsubscribe1;
  }, []);

  console.log(camaras);

  const [estado, setEstado] = React.useState("");
  const [fondo, setFondo] = React.useState("");

  React.useEffect(() => {
    if (item == "Puebla") {
      setEstado(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FPuebla%2FPuebla.png?alt=media&token=e91d6a44-24a1-461a-8ddd-ee028a998b70"
      );
      setFondo(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FPueblac.png?alt=media&token=43ebd048-268f-4186-bbba-74682b5ad081"
      )
    } else if (item == "Hidalgo") {
      setEstado(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FHidalgo%2FHidalgo.png?alt=media&token=33721372-ed9e-4095-bb0f-db7ae3964116"
      );
      setFondo(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FPueblac.png?alt=media&token=43ebd048-268f-4186-bbba-74682b5ad081"
      );
    } else if (item == "Veracruz") {
      setEstado(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FVeracruz%2FVeracruz.png?alt=media&token=77480979-4b96-4db9-b13c-7156a029fd65"
      );
      setFondo(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FPueblac.png?alt=media&token=43ebd048-268f-4186-bbba-74682b5ad081"
      );
    } else if (item == "Tlaxcala") {
      setEstado(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FTlaxcala_WIP%2FTlaxcala.png?alt=media&token=d409e779-8ae9-4054-9a4f-64a6d88b7a29"
      );
      setFondo(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FPueblac.png?alt=media&token=43ebd048-268f-4186-bbba-74682b5ad081"
      );
    }
  }, [item]);

  console.log(item); // Outputs the state object with the camera's name

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${fondo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      <img
        style={{
          height: 150,
          width: 300,
          margin: 50,
          alignSelf: "center",
        }}
        src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2F03.png?alt=media&token=c3f750b4-9078-410f-8a9b-9effe21331a3"
      />
      <div
        style={{
          alignSelf: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "85%",
          justifyContent: "center",
          margin: 10,
          padding: 10,
          borderRadius: "15px",
          marginBottom: 10,
          height: 750,
          backgroundColor: "rgba(0,0,0,0.6)",
        }}
      >
        <img
          style={{
            height: 350,
            width: 700,
            marginTop: 20,
            alignSelf: "center",
            marginTop: 0,
          }}
          src={estado}
        />
        <div
          style={{
            width: 1300,
            alignSelf: "center",
            justifyContent: "center",
            marginTop: -90,
            padding: 10,
            marginBottom: 50,
            height: 650,
          }}
        >
          <Swiper
            // install Swiper modules
            effect={"coverflow"}
            modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
            spaceBetween={20}
            slidesPerView={"auto"}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            pagination={{ clickable: true }}
          >
            {camaras.map((i) => (
              <SwiperSlide
                style={{
                  display: "flex",
                  width: 350,
                  height: 500,
                  borderRadius: 15,
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  backgroundImage: `url(${i.background})`,
                  backgroundSize: "cover",
                }}
                key={i.id}
                {...i}
              >
                <Link to={"live"} state={{ title: i.title }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: 130,
                      width: 350,
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      borderRadius: 0,
                      background: "rgba(0,0,0,0.4)",
                      borderWidth: 0,
                      borderColor: "rgba(255,255,255,0.2)",
                    }}
                  >
                    <img
                      src={i.portada}
                      style={{ height: 200, width: 900, alignSelf: "center" }}
                    />
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <Footer />
    </div>
  );
}
