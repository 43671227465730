import React from 'react'

const Buscador = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
      <a style={{ textAlign: 'center' }} > Buscador exitoso </a>
    </div>
  )
}

export default Buscador

