import React from 'react'
import './Footer.css'
import fb from '../assets/facebook.png'
import twitter from '../assets/twitter.png'
import LinkedIn from '../assets/linkedin.png'
import inst from '../assets/instagram.png'

export default function Footer() {
  return (
    <div className='footer'>
        <div className="sb-footer-section-padding">
            <div className="sb-footer-links">
                <div className="sb-footer-links-div">
                    <img 
                      style={{ height: 60, width: 120, marginBottom: 20 }}
                      src='https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLogos%2FLogo_transparente.png?alt=media&token=3165b93b-50ca-4b53-8a28-c61cdb1d679f' />
                    <h4>Turixcam</h4>
                    <a href="/employer">
                        <p>Mision y vision</p>
                    </a>
                    <a href="/healthplan">
                        <p>Acerca de Turixcam</p>
                    </a>
                </div>
                <div className="sb-footer-links-div">
                    <h4>Contactanos</h4>
                    <a href="/employer">
                        <p>soporte@turixcam.com</p>
                    </a>
                    <a href="/healthplan">
                        <p>administracion@turixcam.com</p>
                    </a>
                </div>
                <div className="sb-footer-links-div">
                    <h4>Contenido</h4>
                    <a href="/employer">
                        <p>Camaras en vivo</p>
                    </a>
                    <a href="/employer">
                        <p>Eventos</p>
                    </a>
                    <a href="/employer">
                        <p>Comunidad</p>
                    </a>
                </div>
                <div className="sb-footer-links-div">
                    <h4>Descargas</h4>
                    <a href="/about">
                        <img
                          style={{ height: 45, width: 180, marginTop: 10 }} 
                          src='https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2Ficono-android-store.png?alt=media&token=07d8e467-d49d-4d03-90eb-3d21066323c7'  />
                    </a>
                    <a href="/press">
                    <img
                          style={{ height: 45, width: 180, marginTop: 20 }} 
                          src='https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2Ficono-apple-store.png?alt=media&token=9cffc959-4c36-4b99-9489-1858c3083c6e'  />
                    
                    </a>
                </div>
                <div className="sb-footer-links-div">
                    <h4>Siguenos en: </h4>
                    <div className="socialmedia">
                        <p><img src={fb} alt=''/></p>
                        <p><img src={twitter} alt=''/></p>
                        <p><img src={LinkedIn} alt=''/></p>
                        <p><img src={inst} alt=''/></p>
                    </div>
                </div>
            </div>
            <hr></hr>

                <div className="sb-footer-below">
                    <div className="sb-footer-copyright">
                        <p>@{new Date().getFullYear()} Turixcam®️ Todos los derechos reservados.</p>
                    </div>
                    <div className="sb-footer-below-links">
                        <a href="/term">
                            <div>
                                <p>Terminos y condiciones</p>
                            </div>
                        </a>
                        <a href="/security">
                            <div>
                                <p>Contactanos</p>
                            </div>
                        </a>
                        <a href="/cookie">
                            <div>
                                <p>Normas comunitarias</p>
                            </div>
                        </a>
                    </div>
                </div>
        </div>
    </div>
  )
}
