import "./login.css";
import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { auth, db } from "../config/firebaseConfig";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { Link, Navigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import { addDoc, doc, setDoc } from "firebase/firestore";

const Registro = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeName = (event) => {
    setDisplayName(event.target.value);
  };

  const handlePass1 = (event) => {
    setPassword1(event.target.value);
  };

  const [validate, setValidate] = React.useState(false);

  const [success, setSuccess] = React.useState(false);

  const [error, setError] = React.useState(false);

  const [successVEF, setSuccessVEF] = React.useState(false);

  

  const handleSubmit = () => {
    const data ={
      correo: email,
      nombre: displayName,
      premium: "free",
      f1: [],
      imagenesApp: [
        "https://turixcam-images.b-cdn.net/hidalgo/FondosConfig/Zacatlan.png",
        "https://turixcam-images.b-cdn.net/hidalgo/FondosConfig/Tuliman.png",
        "https://i.redd.it/u9z9rmz2ryf21.jpg",
      ],
      imgFondo: "https://i.redd.it/u9z9rmz2ryf21.jpg",
      imgGlobo: [
        "https://staticurix.b-cdn.net/Recursos%20WEB/PREMIUM/globo-premium.png",
        "https://turixcam-images.b-cdn.net/Backgrounds/inicio/globo.png",
      ],
      imgIcon: "https://turixcam-images.b-cdn.net/Backgrounds/inicio/globo.png",
      creditos: 0,
      image: "https://turixcam-images.b-cdn.net/Backgrounds/adaptive-icon.png",
      portada: "https://turixcam-images.b-cdn.net/Backgrounds/zacatlan.jpg",
    }
    FinallyProcess(data);
  };

  const FinallyProcess = async(data) => {
    const d = data;
    console.log(d)
    await setDoc(doc(db, "Usuarios", email), d)
      .then(() => {
        setSuccessVEF(true)
        setTimeout(() => {
          setSuccessVEF(false);
        }, 6000);
        setTimeout(() => {
          setSuccess(!success);
        }, 7500);
      })
      .catch((e) => console.log("Error al registrarse", e));
    console.log(email, password);
  }

  const SignUp = async (e) => {
    if (email == "" && password == "" && displayName == "" && password1 == "") {
      console.log("Favor de llenar todos los datos");
    } else {
      const { user } = await createUserWithEmailAndPassword(auth, email, password)
        .then()
        .catch((error) => {
          console.log(error);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 6000);

        });
      await sendEmailVerification(user).then(() => {
        console.log("Email enviado")
        handleSubmit()
      })
      .catch((e) => console.log('Error al verificar', e))  
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        position: "relative",
        padding: 50,
      }}
    >
      <div className="img_login"></div>
      <header className="App-header_login">
        {error === true && (
          <Alert
            sx={{ alignSelf: "flex-end", marginBottom: "15px" }}
            variant="filled"
            severity="error"
          >
            Favor de verificar todos sus datos.
          </Alert>
        )}
        <div className="box_black">
          <img
            style={{
              height: 100,
              width: 200,
              alignSelf: "center",
            }}
            src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2F03.png?alt=media&token=c3f750b4-9078-410f-8a9b-9effe21331a3"
          />
          <h1 className="title_login">Crear cuenta</h1>

          <Box
            component="form"
            sx={{
              "& > :not(style)": {
                m: 5,
                display: "flex",
                width: "40ch",
                justifyContent: "center",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
              }}
              required
              label="Nombre de usuario"
              color="warning"
              variant="filled"
              fullWidth
              focused
              value={displayName}
              onChange={(e) => handleChangeName(e)}
            />

            <TextField
              sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
              }}
              required
              label="Correo"
              color="warning"
              fullWidth
              variant="filled"
              focused
              value={email}
              onChange={(e) => handleChangeEmail(e)}
            />

            <FormControl
              sx={{
                m: 1,
                width: "",
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
              }}
              color="warning"
              focused
              variant="filled"
              value={password}
              onChange={(e) => handleChange(e)}
            >
              <InputLabel htmlFor="filled-adornment-password">
                Contraseña
              </InputLabel>

              <FilledInput
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="warning"
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              sx={{
                m: 1,
                width: "",
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
              }}
              color="warning"
              focused
              variant="filled"
              value={password1}
              onChange={(e) => handlePass1(e)}
            >
              <InputLabel htmlFor="filled-adornment-password">
                Confirmar Contraseña
              </InputLabel>

              <FilledInput
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="warning"
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                value={password}
                valueAgain={password1}
                messages={{
                  minLength: "La contraseña debe tener más de 8 caracteres.",
                  specialChar:
                    "La contraseña debe tener caracteres especiales.",
                  number: "La contraseña debe tener un número.",
                  capital: "La contraseña debe tener una letra mayúscula.",
                  match: "Las contraseñas deben coincidir.",
                }}
                style={{ fontSize: 16, textAlign: "justify" }}
                onChange={(isValid) => setValidate(isValid)}
              />
            </div>
          </Box>
          {validate == false && (
            <Button
              sx={{ padding: "12px", borderRadius: "10px", width: "45%" }}
              color="inherit"
              variant="contained"
            >
              Registrarte
            </Button>
          )}
          {validate == true && (
            <Button
              onClick={(e) => SignUp(e)}
              sx={{ padding: "12px", borderRadius: "10px", width: "45%" }}
              color="warning"
              variant="contained"
            >
              Registrarte
            </Button>
          )}
          {successVEF === true && (
            <Alert
              sx={{ alignSelf: "flex-end", marginBottom: "15px" }}
              variant="filled"
              severity="error"
            >
              Registro correcto, favor de verificar su correo, volvera al inicio
              de sesion en 7 segundos.
            </Alert>
          )}
          <a style={{ margin: 10, fontSize: 14 }}>¿Ya tienes cuenta? </a>
          <Link to={"/"} style={{ width: "100%" }}>
            <Button
              sx={{ padding: "12px", borderRadius: "10px", width: "45%" }}
              color="warning"
              variant="contained"
            >
              Inicia sesion
            </Button>
          </Link>

          {success === true && <Navigate to={"/"} />}
        </div>
      </header>
    </div>
  );
};

export default Registro;