import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./SwiperStyles2.css";

import { EffectCoverflow, Pagination } from "swiper/modules";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

export default function CardClientes({ cliente, resumen, imagen, detalles }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    
        <Card
          sx={{
            maxWidth: "100%",
            maxHeight: "600px",
            minHeight: "200px",
            alignSelf: "center",
            borderRadius: '15px'
          }}
        >
          <CardHeader
            avatar={
              <Avatar sx={{}} aria-label="recipe">
                <img src={imagen} />
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={cliente}
            subheader="September 14, 2016"
          />

          <CardContent>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {resumen}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent style={{ backgroundColor: 'transparent' }} >
              <Swiper
                style={{
                  display: "flex",
                  width: 600,
                  height: 300,
                  borderRadius: 15,
                  backgroundColor: "rgba(0,0,0,0.0)",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 0,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FPUEBLAv1.png?alt=media&token=de8480a0-cea5-4670-941e-5f8f6d895661" />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-2.jpg" style={{ opacity: 'unset' }} />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
                </SwiperSlide>
                <SwiperSlide style={{ 
                    opacity: 'unset'
                 }}>
                  <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
                </SwiperSlide>
              </Swiper>
            </CardContent>
          </Collapse>
        </Card>
  );
}

// Linea 89 - 91: borrar <Typography></Typography>
// Incluir en el espacio vacio, <Sweeper>
