import * as React from "react";
import CardClientes from "./cardClientes";
import { Grid2 } from "@mui/material";
import { useLocation } from "react-router-dom";
import Footer from "./footer";
import { db } from "../config/firebaseConfig";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { color } from "framer-motion";

export default function ListaC() {
  const location = useLocation();

  const title = location.state.title;
  const tipo = location.state.tipo;

  const [publicidad, setPublicidad] = React.useState([]);

  useEffect(() => {
    const collectionRef = collection(db, "Publicidad");
    const q = query(
      collectionRef,
      where("lugar", "==", title),
      where("tipo", "==", tipo)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log("Datos obtenidos");

      setPublicidad(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          cliente: doc.data().cliente,
          resumen: doc.data().resumen,
          detalles: doc.data().detalles,
          imagen: doc.data().imagen,
          estrellas: doc.data().estrellas,
          prioridad: doc.data().prioridad,
          abrido: doc.data().abrido,
          horario: doc.data().horario,
          direccion: doc.data().direccion,
          telefono: doc.data().telefono,
          galeria: doc.data().galeria,
        }))
      );
    });

    return unsubscribe;
  }, []);

  const [finalBackground, setFinalBackground] = useState("");

  const background = {
    imageChignahuapan:
      "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FChignahuapanc.png?alt=media&token=0414912b-4b00-4c20-9da4-21864bb76082",
    imageZacatlan:
      "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FZacatlanc.png?alt=media&token=1f7c742e-0515-44b3-b87c-b032c6bdf674",
    imageVeracruz:
      "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FVeracruzc.png?alt=media&token=ce209254-c9da-422e-942c-56199fc3e785",
    imageTuliman:
      "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FTulimanc.png?alt=media&token=ee6d829d-88e0-4e03-91d5-b4a5df81323c",
    imagePuebla:
      "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FServicios%2FPueblac.png?alt=media&token=43ebd048-268f-4186-bbba-74682b5ad081",
  };

  useEffect(() => {
    if (title == "Chignahuapan") {
      setFinalBackground(background.imageChignahuapan);
    } else if (title == "Tuliman") {
      setFinalBackground(background.imageTuliman);
    } else if (title == "Tecolutla") {
      setFinalBackground(background.imageVeracruz);
    } else if (title == "Zacatlan") {
      setFinalBackground(background.imageZacatlan);
    } else {
      setFinalBackground(
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FFondos%2Ffondo.png?alt=media&token=609e9225-c7a1-4006-bb97-d2a477960f5a"
      );
    }
  });

  console.log(publicidad);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${finalBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <header
        style={{
          display: "flex",
          padding: 5,
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            height: 200,
            width: 400,
            marginTop: 50,
            position: "relative",
          }}
          src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLogos%2FLogo_transparente.png?alt=media&token=3165b93b-50ca-4b53-8a28-c61cdb1d679f"
        />
      </header>

      <div>
        <div
          style={{
            display: "flex",
            padding: 5,
            alignSelf: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <h2
            style={{
              padding: 5,
              backgroundColor: "rgba(0,0,0,0.7)",
              borderRadius: 5,
              alignSelf: "center",
              margin: 10,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {title}
          </h2>
        </div>
      </div>

      <Grid2
        sx={{
          padding: 5,
          backgroundColor: "rgba(240,240,240,0.7)",
          borderRadius: 5,
          alignSelf: "center",
          margin: 10,
          display: "flex",
          flexDirection: "row",
        }}
        container
        spacing={3}
        columns={32}
      >
        {publicidad.map((item) => (
          <Grid2 sx={{ alignSelf: "center" }} size={16}>
            <CardClientes
              cliente={item.cliente}
              imagen={item.imagen}
              resumen={item.resumen}
              detalles={item.detalles}
            />
          </Grid2>
        ))}
      </Grid2>
      <Footer />
    </div>
  );
}
